import * as React from "react";
import { Authenticator, ConfirmSignUp } from "aws-amplify-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { useAuth } from "./context/auth-context";
import MyConfirmSignUp from "./components/signup-confirm";

const AuthenticatedApp = React.lazy(() => import("./authenticated-app"));
const queryClient = new QueryClient();

function App() {
  const { user } = useAuth();

  if (!user) {
    return (
      <Authenticator
        hide={[ConfirmSignUp]}
        signUpConfig={{ hiddenDefaults: "phone_number" }}
      >
        <MyConfirmSignUp override={"confirmSignUp"} />
      </Authenticator>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <AuthenticatedApp />
      </React.Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
