import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import "semantic-ui-css/semantic.min.css";

import App from "./app";
import { AuthProvider } from "./context/auth-context";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

Amplify.configure({
  Auth: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_ssQc6e64E",
    userPoolWebClientId: "n0rjb69svuqce2qcd46k7457m",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
