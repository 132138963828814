import React from "react";
import Amplify, { Hub } from "aws-amplify";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [triggerFetch, setTriggerFetch] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      if (isMounted) {
        setLoading(true);
        setError(null);
      }
      try {
        if (isMounted) {
          const data = await Amplify.Auth.currentAuthenticatedUser();
          if (data) {
            setLoading(false);
            setUser(data);
          }
        }
      } catch (err) {
        if (isMounted) {
          setLoading(false);
          setError(err);
        }
      }
    };

    const _onAuthChange = data => {
      const { payload } = data;
      if (isMounted && payload.event === "signIn") {
        setTriggerFetch(true);
        // console.log("Signed in...");
      }
    };

    Hub.listen("auth", _onAuthChange);
    fetchUserData();

    return () => {
      Hub.remove("auth", _onAuthChange);
      isMounted = false;
    };
  }, [triggerFetch]);

  const handleSignOut = async () => {
    try {
      // console.log("Signing out...");
      await Amplify.Auth.signOut();
      setTriggerFetch(false);
      setUser(null);
    } catch (err) {
      console.error("Error signing out user ", err);
    }
  };

  if (loading) {
    return <div>Please wait...</div>;
  }

  if (error && error !== "not authenticated") {
    console.log(error);
  }

  return <AuthContext.Provider value={{ user, handleSignOut }} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
