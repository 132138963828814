import React from "react";

import AuthPiece from "aws-amplify-react/lib/Auth/AuthPiece";
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Link,
  SectionFooterSecondaryContent
} from "aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React";

import { auth } from "aws-amplify-react/lib/Amplify-UI/data-test-attributes";

export default class ConfirmSignUp extends AuthPiece {
  constructor(props) {
    super(props);

    this._validAuthStates = ["confirmSignUp"];
  }

  showComponent(theme) {
    const { hide } = this.props;
    const username = this.usernameFromAuthData();

    if (hide && hide.includes(ConfirmSignUp)) {
      return null;
    }

    return (
      <FormSection theme={theme} data-test={auth.confirmSignUp.section}>
        <SectionHeader
          theme={theme}
          data-test={auth.confirmSignUp.headerSection}
        >
          Confirm Sign Up
        </SectionHeader>
        <SectionBody theme={theme} data-test={auth.confirmSignUp.bodySection}>
          Thank you {username}. Your account has been created, but must now be
          confirmed by an admin. Please contact{" "}
          <a href="mailto: admin@adelaenergy.com">admin@adelaenergy.com</a> if
          we are not aware of your registration.
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterSecondaryContent theme={theme}>
            <Link
              theme={theme}
              onClick={() => this.changeState("signIn")}
              data-test={auth.confirmSignUp.backToSignInLink}
            >
              Back to Sign In
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    );
  }
}
